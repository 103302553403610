































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { SkyHubUser } from '@/models/SkyHubUser';
import DropDown from '@/components/DropDownComponent.vue';
import { UserRole, UserRoleLabels } from '@/models/UserRoles';
import { Sorter } from '@/util/Sorter';
import TableSortArrowComponent from '@/components/TableSortArrowComponent.vue';

const modules = { adminModule: 'adminModule' };

@Component<AdminPage>({
  components: {
    DropDown,
    TableSortArrowComponent
  }
})
export default class AdminPage extends Vue {
  private roleLabels = UserRoleLabels;
  private roles = Object.values(UserRole).filter((x) => typeof x === 'number');
  private selected: string[] = [];
  public sorting: Sorter = new Sorter();
  private checkAll = false;

  @Prop({ default: () => [] })
  private users!: SkyHubUser[];

  @Prop({ default: () => null })
  private currentUser!: SkyHubUser;

  @Prop({ default: () => '' })
  private pageTitle!: string;

  //handles the check all button
  private selectAllHandler() {
    this.checkAll = !this.checkAll;
    this.selected = [];
    if (this.checkAll) {
      this.selected = this.users.map((user) => user.uid);
      this.checkAll = true;
    }
  }
  private updateCheckAll() {
    if (this.selected.length === this.users.length) {
      this.checkAll = true;
    } else {
      this.checkAll = false;
    }
  }

  private async changeRole(user: SkyHubUser, newRole: UserRole) {
    if (user.uid == this.currentUser.uid) {
      return;
    }
    if (user.role == newRole) {
      return;
    }
    user.role = newRole;
    await this.$store.dispatch(
      `${modules.adminModule}/updateSkyHubUserRole`,
      user
    );
  }

  private sortByName() {
    this.sorting.sort((p) => p.displayName, this.users, 'displayName');
  }

  private sortByEmail() {
    this.sorting.sort((p) => p.email, this.users, 'email');
  }

  private sortByRole() {
    this.sorting.sort((p) => p.role, this.users, 'role');
  }
}
