var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "container px-8 mx-auto h-4/5" }, [
    _c(
      "h1",
      { staticClass: "my-6 text-2xl font-bold", attrs: { id: "table-title" } },
      [_vm._v(" " + _vm._s(_vm.pageTitle) + " ")]
    ),
    _c(
      "div",
      { staticClass: "flex px-8 pt-4 border border-black bg-gray-50 " },
      [
        _c(
          "h2",
          {
            staticClass:
              "inline px-3 pb-2 font-bold border-b-4 border-green-500"
          },
          [_vm._v(" Users ")]
        ),
        _c("div", { staticClass: "flex flex-col items-end w-full" }, [
          _vm._v(" " + _vm._s(_vm.selected.length) + " selected ")
        ])
      ]
    ),
    _c(
      "div",
      {
        staticClass:
          "overflow-y-scroll border-b border-l border-r border-black h-5/6"
      },
      [
        _c(
          "table",
          {
            staticClass: "w-full table-auto",
            attrs: { "aria-describedby": "table-title" }
          },
          [
            _c("thead", [
              _c("tr", { staticClass: "text-left border-b" }, [
                _c(
                  "th",
                  {
                    staticClass: "sticky top-0 px-8 py-2 bg-gray-50",
                    attrs: { scope: "col" }
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.checkAll,
                          expression: "checkAll"
                        }
                      ],
                      staticClass: "mr-4",
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.checkAll)
                          ? _vm._i(_vm.checkAll, null) > -1
                          : _vm.checkAll
                      },
                      on: {
                        click: _vm.selectAllHandler,
                        change: function($event) {
                          var $$a = _vm.checkAll,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.checkAll = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.checkAll = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.checkAll = $$c
                          }
                        }
                      }
                    }),
                    _c(
                      "span",
                      {
                        staticClass: "cursor-pointer",
                        on: {
                          click: function($event) {
                            return _vm.sortByName()
                          }
                        }
                      },
                      [
                        _vm._v(" Name "),
                        _c("table-sort-arrow-component", {
                          attrs: { sorting: _vm.sorting, name: "displayName" }
                        })
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "th",
                  {
                    staticClass: "sticky top-0 px-8 py-2 bg-gray-50",
                    attrs: { scope: "col" }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "cursor-pointer",
                        on: {
                          click: function($event) {
                            return _vm.sortByEmail()
                          }
                        }
                      },
                      [
                        _vm._v(" Email "),
                        _c("table-sort-arrow-component", {
                          attrs: { sorting: _vm.sorting, name: "email" }
                        })
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "th",
                  {
                    staticClass: "sticky top-0 px-8 py-2 bg-gray-50",
                    attrs: { scope: "col" }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "cursor-pointer",
                        on: {
                          click: function($event) {
                            return _vm.sortByRole()
                          }
                        }
                      },
                      [
                        _vm._v(" Role Selection "),
                        _c("table-sort-arrow-component", {
                          attrs: { sorting: _vm.sorting, name: "role" }
                        })
                      ],
                      1
                    )
                  ]
                )
              ])
            ]),
            _c(
              "tbody",
              _vm._l(_vm.users, function(user, index) {
                return _c(
                  "tr",
                  { key: index, staticClass: "skyhub-table-row" },
                  [
                    _c("td", { staticClass: "px-8 py-4 whitespace-no-wrap" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selected,
                            expression: "selected"
                          }
                        ],
                        staticClass: "mr-4",
                        attrs: { type: "checkbox" },
                        domProps: {
                          value: user.uid,
                          checked: Array.isArray(_vm.selected)
                            ? _vm._i(_vm.selected, user.uid) > -1
                            : _vm.selected
                        },
                        on: {
                          change: [
                            function($event) {
                              var $$a = _vm.selected,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = user.uid,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 && (_vm.selected = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.selected = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.selected = $$c
                              }
                            },
                            function($event) {
                              return _vm.updateCheckAll()
                            }
                          ]
                        }
                      }),
                      _vm._v(" " + _vm._s(user.displayName) + " ")
                    ]),
                    _c("td", { staticClass: "px-8 py-4" }, [
                      _vm._v(" " + _vm._s(user.email) + " ")
                    ]),
                    _c("td", { staticClass: "px-8 py-4" }, [
                      _c("div", [
                        _c(
                          "select",
                          {
                            attrs: {
                              id: "dropdown-platform",
                              disabled: user.uid === _vm.currentUser.uid
                            },
                            on: {
                              change: function($event) {
                                return _vm.changeRole(
                                  user,
                                  $event.target.selectedIndex
                                )
                              }
                            }
                          },
                          _vm._l(_vm.roles, function(role) {
                            return _c(
                              "option",
                              {
                                key: role,
                                domProps: { selected: user.role === role }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.roleLabels.get(role)) + " "
                                )
                              ]
                            )
                          }),
                          0
                        )
                      ])
                    ])
                  ]
                )
              }),
              0
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }